export const getCurrentDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken(),
  };
};

export const hideText = (text, sliceLength = 20) => {
  return text?.length > sliceLength
    ? `${text?.slice(0, sliceLength)}...`
    : text;
};

export const getInitials = (name) => {
  if (!name || typeof name !== "string" || name.trim().length === 0) {
    return "N/A";
  }
  const nameParts = name.split(" ").filter((part) => part.length > 0);
  if (nameParts.length === 0) {
    return "N/A";
  }
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  }
  const firstInitial = nameParts[0]?.charAt(0).toUpperCase();
  const lastInitial = nameParts[nameParts.length - 1]?.charAt(0).toUpperCase();
  return firstInitial + lastInitial;
};

export const capitalizeLetters = (string) => {
  if (string.includes('-')) {
    return string;
  }
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}